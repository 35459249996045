import axios from 'axios';
import {getDomain, getIs24LegacyHost} from './resolveHost';
import get from 'lodash.get';
import {ContingentBox} from "./ContingentBox";

const remoteLog = (message = {}) => {
  if (typeof window !== 'undefined') {
    get(window, 'IS24.exceptionLog', () => {
    })(message);
  }
};

axios.defaults.withCredentials = false;

axios.interceptors.response.use(response => response, error => {
  remoteLog(new Error(`Error while calling: ${get(error, 'config.url', 'unknown')}`));
  return Promise.resolve();
});

const loadAllBoxes = async (accessToken: string, remoteUrls: string[]): Promise<{ boxes: ContingentBox[], hasErrors: boolean }> => {
  const responses = await Promise.all(
    remoteUrls.map(url =>
      axios.get<ContingentBox[]>(url, {headers: {Authorization: `Bearer ${accessToken}`}})
    )
  );
  let notSuccessfulCall = 0;
  const boxes: ContingentBox[] = [];
  for (const response of responses) {
    if (response && response.status === 200) {
      boxes.push(...response.data)
    } else {
      notSuccessfulCall++
    }
  }
  return {boxes, hasErrors: notSuccessfulCall > 0};
};

// Add your new remote call here
const remoteUrls = [
  `https://${getIs24LegacyHost()}/leistungen/kontingente.json`,
  `https://leistung.gewerbe.${getDomain()}/booking-overview`,
  `https://api.residential-sps.${getDomain()}/contingentboxes`
];

export {loadAllBoxes, remoteUrls};


